import React from "react";
import UiKitRow, { RowContent } from "../../components/UiKitRow";
import ContentTitle from "../../components/ContentTitle";

function UKitContentTitle() {
  return (
    <UiKitRow title="ContentTitle">
      <RowContent>
        <ContentTitle title="Philosophy">
          We have relationships with leading Healthcare,{" "}
          <strong>Social impact Angel syndicates</strong>, major Venture Private
          Equity firms and renown.
        </ContentTitle>
      </RowContent>

      <RowContent>
        <ContentTitle title="Experience">
          We think that the success of lorem ipsum dolor is the main key
        </ContentTitle>
      </RowContent>

      <RowContent>
        <ContentTitle title="Regulation" spaces="6">
          We have relationships with leading Healthcare,{" "}
          <strong>Social impact Angel syndicates</strong>, major Venture Private
          Equity firms and renown Accelerators. major Venture Private Equity
          firms and renown Accelerators. <strong>Major Venture</strong> Private
          Equity firms and renown Accelerators. major Venture Private Equity
          firms and renown Accelerators
        </ContentTitle>
      </RowContent>
    </UiKitRow>
  );
}

export default UKitContentTitle;
