import React from "react";
import { Box } from "@chakra-ui/react";

function RowContent({ children }) {
  return (
    <Box
      as="div"
      width="100%"
      padding="5"
      background="#fcfcfc"
      border="3px solid #eeeeee"
      borderTop="0"
    >
      {children}
    </Box>
  );
}

export default RowContent;
