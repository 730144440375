import React, { useMemo } from "react";
import moment from "moment";
import { Box, Img, Text } from "@chakra-ui/react";

const shortify = (text, length) => {
  if (text.length > length) {
    text = text.substr(0, length - 3) + "...";
  }

  return text;
};

function NewsCard({ image, title, text, date }) {
  const articleTitle = useMemo(() => shortify(title, 22), [title]);
  const articleText = useMemo(() => shortify(text, 120), [text]);

  return (
    <Box>
      <Box>
        <Img
          borderRadius="12px"
          draggable={false}
          src={image}
          maxWidth="100%"
          width="100%"
          height="180px"
          objectFit="cover"
        />
      </Box>

      <Box paddingLeft="20px" paddingRight="20px" paddingTop="20px">
        <Text lineHeight="30px" fontSize="24px" fontWeight="bold">
          {articleTitle}
        </Text>
        <Text lineHeight="16px" fontSize="14px" fontWeight="light">
          {articleText}
        </Text>
        <Text
          marginTop="7px"
          lineHeight="16px"
          fontSize="11px"
          fontWeight="bold"
        >
          {moment(date).format("DD MMMM YYYY")}
        </Text>
      </Box>
    </Box>
  );
}

export default NewsCard;
