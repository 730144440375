import React from "react";
import { Box, VStack } from "@chakra-ui/react";
import NotFoundPage from "./404";

import UiKuiActionButton from "../views/UiKit/UiKitActionButton";
import UiKitContentTitle from "../views/UiKit/UiKitContentTitle";
import UiKitNewsCard from "../views/UiKit/UiKitNewsCard";
import UiKitFormControl from "../views/UiKit/UiKitFormControl";
import UiKitCustomButton from "../views/UiKit/UiKitCustomButton";

function UiKit(props) {
  const isAmplifyDomain = /amplifyapp\.com/.test(props.hostname);
  if (process.env.NODE_ENV === "production" && !isAmplifyDomain) {
    return <NotFoundPage {...props} />;
  }

  return (
    <Box
      width="100%"
      as="div"
      padding="7"
      display="flex"
      justifyContent="center"
    >
      <VStack
        gridGap={10}
        maxWidth="1200px"
        width="100%"
        display="flex"
        flexDirection="column"
      >
        <UiKuiActionButton />
        <UiKitContentTitle />
        <UiKitNewsCard />
        <UiKitFormControl />
        <UiKitCustomButton />
      </VStack>
    </Box>
  );
}

export default UiKit;
