import React from "react";
import { Box, Text } from "@chakra-ui/react";
import UiKitRowContent from "./RowContent";

function UiKitRow({ title, children }) {
  return (
    <Box as="div" width="100%">
      <Box as="div" width="100%" padding="5" backgroundColor="#eeeeee">
        <Text fontSize="20px" fontWeight="bold">
          {title}
        </Text>
      </Box>

      <Box
        as="div"
        width="100%"
      >
        {children}
      </Box>
    </Box>
  );
}

export default UiKitRow;
export const RowContent = UiKitRowContent;
