import React from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import UiKitRow, { RowContent } from "../../components/UiKitRow";
import FormControl from "../../components/FormControl";

function UiKitFormControl() {
  return (
    <UiKitRow title="Form Control">
      <RowContent>
        <Grid templateColumns="repeat(4, 1fr)" gap={6}>
          <GridItem>
            <FormControl label="Name" labelType="label" borderType="bottom" />
          </GridItem>

          <GridItem>
            <FormControl
              label="Name"
              labelType="label"
              borderType="all"
              borderRadius="5px"
            />
          </GridItem>

          <GridItem>
            <FormControl
              label="Name"
              labelType="placeholder"
              borderType="bottom"
            />
          </GridItem>

          <GridItem>
            <FormControl
              label="Name"
              labelType="placeholder"
              borderType="all"
              borderRadius="5px"
            />
          </GridItem>
        </Grid>
      </RowContent>

      <RowContent>
        <Grid templateColumns="repeat(4, 1fr)" gap={6}>
          <GridItem>
            <FormControl
              multiline={true}
              label="Message"
              labelType="label"
              borderType="bottom"
            />
          </GridItem>

          <GridItem>
            <FormControl
              multiline={true}
              label="Message"
              labelType="label"
              borderType="all"
              borderRadius="5px"
            />
          </GridItem>

          <GridItem>
            <FormControl
              multiline={true}
              label="Message"
              labelType="placeholder"
              borderType="bottom"
            />
          </GridItem>

          <GridItem>
            <FormControl
              multiline={true}
              label="Message"
              labelType="placeholder"
              borderType="all"
              borderRadius="5px"
            />
          </GridItem>
        </Grid>
      </RowContent>
    </UiKitRow>
  );
}

export default UiKitFormControl;
