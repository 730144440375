import React, { useMemo } from "react";
import { Flex, Box } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";

// color: string | { dot: string, arrow: string, text: string }
function ActionButton({ text, color, withMinWidth, onClick }) {
  const colorDot = useMemo(
    () => ("object" !== typeof color ? color : color.dot || "black.1000"),
    [color]
  );

  const colorArrow = useMemo(
    () => ("object" !== typeof color ? color : color.arrow || "black.1000"),
    [color]
  );

  const colorText = useMemo(
    () => ("object" !== typeof color ? color : color.text || "black.1000"),
    [color]
  );

  const colorBackground = useMemo(
    () => ("object" !== typeof color && "white" !== color ? "white" : "transparent"),
    [color]
  );

  return (
    <Flex
      width="100%"
      maxWidth={{ base: "calc(100vw - 40px)", md: "420px" }}
      minWidth={
        !withMinWidth ? undefined : { base: "calc(100vw - 40px)", md: "320px" }
      }
      minHeight="32px"
      maxHeight="32px"
      overflowY="hidden"
      justifyContent="space-between"
      cursor="pointer"
      onClick={onClick}
      backgroundColor={colorBackground}
    >
      <Flex alignItems="center">
        <Flex as="div" alignItems="center">
          <Box
            marginRight="12px"
            height="12px"
            width="12px"
            borderRadius="50%"
            background={colorDot}
          />
        </Flex>
        <Flex
          as="div"
          alignItems="center"
          color={colorText}
          fontSize="16px"
          height="15px"
          lineHeight="21px"
          fontFamily="Mont"
          fontWeight="bold"
        >
          {text}
        </Flex>
      </Flex>
      <Box>
        <ArrowForwardIcon
          marginTop="-8px"
          width="48px"
          height="48px"
          color={colorArrow}
        />
      </Box>
    </Flex>
  );
}

ActionButton.defaultProps = {
  fullWhite: false,
  color: "blue.1000",
  withMinWidth: true,
};

export default ActionButton;
