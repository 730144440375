import React from "react";
import { Box, Text } from "@chakra-ui/react";
import PageContainer from "../components/PageContainer";

function NotFoundPage() {
  return (
    <Box paddingY="6xl">
      <PageContainer>
        <Text fontSize="5xl" textAlign="center">
          Error 404
        </Text>
      </PageContainer>
    </Box>
  );
}

export default NotFoundPage;
