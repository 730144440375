import React from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import UiKitRow, { RowContent } from "../../components/UiKitRow";
import { Button } from "@chakra-ui/react";

function UiKitCustomButton() {
  return (
    <UiKitRow title="Custom Button">
      <RowContent>
        <Grid templateColumns="repeat(4, 1fr)" gap={6}>
          <GridItem>
            <Button
              backgroundColor="blue.600"
              color="white"
              fontSize="sm"
              height="3xl"
              minWidth="5xl"
              borderRadius={0}
            >
              Default Button
            </Button>
          </GridItem>

          <GridItem>
            <Button
              variant="outline"
              backgroundColor="blue.600"
              color="white"
              fontSize="sm"
              height="3xl"
              minWidth="5xl"
              borderRadius={0}
            >
              Outlined Button
            </Button>
          </GridItem>

          <GridItem>
            <Button
              backgroundColor="blue.600"
              color="white"
              fontSize="sm"
              height="3xl"
              minWidth="5xl"
            >
              Rounded Button
            </Button>
          </GridItem>

          <GridItem>
            <Button
              backgroundColor="blue.600"
              color="white"
              fontSize="sm"
              height="2xl"
              minWidth="4xl"
            >
              Small Button
            </Button>
          </GridItem>
        </Grid>
      </RowContent>
    </UiKitRow>
  );
}

export default UiKitCustomButton;
