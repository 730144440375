import React from "react";
import UiKitRow, { RowContent } from "../../components/UiKitRow";
import ActionButton from "../../components/ActionButton";

function UiKit() {
  return (
    <UiKitRow title="Action Button">
      <RowContent>
        <ActionButton text="Read more" color="blue.600" />
      </RowContent>
      <RowContent>
        <ActionButton text="Read more" color="red" />
      </RowContent>
      <RowContent>
        <ActionButton
          text="See all"
          color={{
            dot: "blue.100",
            text: "black.200",
            arrow: "black.200",
          }}
        />
      </RowContent>
    </UiKitRow>
  );
}

export default UiKit;
