import React from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import UiKitRow, { RowContent } from "../../components/UiKitRow";
import NewsCard from "../../components/NewsCard";

function UiKitNewsCard() {
  const data = [
    {
      image:
        "https://www.conchovalleyhomepage.com/wp-content/uploads/sites/83/2020/05/BREAKING-NEWS-GENERIC-1.jpg?strip=1",
      title: "Title of the latest news",
      text: "Fund is the most relationships with leading Healthcare, Social impact Angel syndicates, major Venture Private Equity firms.",
      date: new Date(),
    },
    {
      image:
        "https://www.conchovalleyhomepage.com/wp-content/uploads/sites/83/2020/05/BREAKING-NEWS-GENERIC-1.jpg?strip=1",
      title: "Title of the latest news",
      text: "Fund is the most relationships with leading Healthcare, Social impact Angel syndicates, major Venture Private Equity firms.",
      date: new Date(),
    },
    {
      image:
        "https://www.conchovalleyhomepage.com/wp-content/uploads/sites/83/2020/05/BREAKING-NEWS-GENERIC-1.jpg?strip=1",
      title: "Title of the latest news",
      text: "Fund is the most relationships with leading Healthcare, Social impact Angel syndicates, major Venture Private Equity firms.",
      date: new Date(),
    },
  ];
  return (
    <UiKitRow title="News Card">
      <RowContent>
        <Grid templateColumns="repeat(3, 1fr)" gap={6}>
          {data.map((row, i) => (
            <GridItem key={`NewsCard-${i}`}>
              <NewsCard
                image={row.image}
                title={row.title}
                text={row.text}
                date={row.date}
              />
            </GridItem>
          ))}
        </Grid>
      </RowContent>
    </UiKitRow>
  );
}

export default UiKitNewsCard;
